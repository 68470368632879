import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addWhiteBoard, removeWhiteBoard, newActiveWhiteBoard } from '../../redux/actions/whiteBoardAction'

// footer hide show function
function footerToggle() {
  var element = document.getElementsByTagName("body")[0];

  if (element.classList) {
    element.classList.toggle("footerClose");
  } else {
    var classes = element.className.split(" ");
    var i = classes.indexOf("footerClose");

    if (i >= 0)
      classes.splice(i, 1);
    else
      classes.push("footerClose");
    element.className = classes.join(" ");
  }
}

class Footer extends Component {
  render() {
    const { whiteBoardReduxState } = this.props
    return (
      <footer className="footerSection">
        <Link
          to=""
          className="footerSection__arrow"
          onClick={(e) => {
            e.preventDefault()
            footerToggle()
          }}
        >
          <i className="icon-drop_dwon_up"></i>
        </Link>
        <div className="footerSection__inner align-items-center justify-content-between">
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault()
              // addModalbtn()
            }}

            className="footerSection__inner__icon"
          >
            <i className="icon-add-bg"></i>
          </Link>
          <ul className="list-inline footerSection__inner__right mb-0">
            {
              whiteBoardReduxState.whiteBoards.length < 10 && (
                <li
                  className="list-inline-item"
                  onClick={(e) => {
                    this.props.addWhiteBoardInRedux()
                  }}
                >
                  <label className="mb-0 d-flex align-items-center justify-content-center" htmlFor="addFolder">
                    {/* <input type="file" id="addFolder" className="d-none" name="" /> */}
                    <i className="icon-add"></i>
                    <span className="count text-center rounded-circle text-white font-neomd">{whiteBoardReduxState.whiteBoards.length}</span>
                  </label>
                  <span className="d-block">New Board</span>
                </li>
              )
            }
            {
              whiteBoardReduxState.whiteBoards.map((board, index) => {
                return <li
                  key={index}
                  className="list-inline-item"
                >
                  <div
                    className="box"
                    onClick={() => {
                      if (whiteBoardReduxState.activeWhiteBoardId !== board) {
                        this.props.newActiveWhiteBoardInRedux(board)
                      }
                    }}
                  >
                    <img src="/assets/images/video.svg" className="img-fluid" alt="icon" />
                  </div>
                  <span className="d-block">Board - {index + 1}</span>
                  <span className="d-block">{board}</span>
                  {
                    (whiteBoardReduxState.whiteBoards.length > 1) && <Link
                      className="delete"
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.removeWhiteBoardInRedux(board)
                      }}
                    >Delete</Link>
                  }
                </li>

              })
            }
          </ul>
        </div>
      </footer>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    whiteBoardReduxState: state.whiteBoardProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    addWhiteBoardInRedux: () => dispatch(addWhiteBoard()),
    removeWhiteBoardInRedux: (data) => dispatch(removeWhiteBoard(data)),
    newActiveWhiteBoardInRedux: (data) => dispatch(newActiveWhiteBoard(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)