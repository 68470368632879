import { UPDATE_TOOL_TYPE } from "../reduxConst/toolTypeConst";

let updateToolType = (state, action) => {
  return {
    ...state,
    ...action.data
  }
}

let toolTypeState = {
  tool: '',
  options: {
    rows: 0,
    columns: 0,
    color: '#000000',
    strokeWidth: 1,
    fill: '',
    angle: 0,
    edge: 6,
    cornerradius: 'no'
  }
}

let toolTypeReducer = (state = toolTypeState, action) => {

  switch (action.type) {

    case UPDATE_TOOL_TYPE:

      return updateToolType(state, action)

    default:
      return state;
  }

}

export default toolTypeReducer;