import React from 'react';
import PropTypes from 'prop-types';

const NotFoundLayout = (props) => {
  const { children } = props;

  return (
    <>
      <main class="main-content homePage">
        <div class="mainWrapper">
          {children}
        </div>
      </main>
    </>
  );
};

NotFoundLayout.propTypes = {
  children: PropTypes.node
};

export default NotFoundLayout;
