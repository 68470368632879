import React, { Component } from 'react'

export default class PageNotFound extends Component {
  render() {
    return (
      <>
        <h1>404 Page Not Found</h1>
      </>
    )
  }
}
