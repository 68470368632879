
import React from 'react'
import { Provider } from 'react-redux'
import './assets/scss/main.scss';
import './assets/css/icomoon.css';

import Routes from './components/routes'
import appStore from './redux/store';

export default function RootApp() {
  return (
    <Provider store={appStore}>
      <Routes />
    </Provider>
  )
}
