export let toolTypeData = (tool, toolOptions, reduxToolData) => {
  let data = {
    tool: '',
    options: {
      color: reduxToolData.options.color,
      strokeWidth: 1,
      fill: '',
      angle: 0,
      edge: 6,
      cornerradius: 'no'
    }
  }

  if (Object.keys(toolOptions).length && 'strokeWidth' in toolOptions && reduxToolData.tool === 'pencil') {
    data.tool = reduxToolData.tool;
  }

  switch (tool) {
    case 'clear':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'undo':
      data = data
      break;
    case 'redo':
      data = data
      break;
    case 'color':
      data = {
        ...reduxToolData,
        options: {
          ...reduxToolData.options,
          color: toolOptions.color
        }
      }
      break;
    case 'arrowDoubleHead':
      data = data
      break;
    case 'arrowFreeSingleHead':
      data = data
      break;
    case 'arrowSingleHead':
      data = data
      break;
    case 'line':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'heartFill':
      data = {
        tool: 'heart',
        options: {
          ...data.options,
          fill: data.options.color
        }
      }
      break;
    case 'pentagonFill':
      data = {
        tool: 'polygon',
        options: {
          ...data.options,
          edge: 5,
          fill: data.options.color
        }
      }
      break;
    case 'triangleFill':
      data = {
        tool: 'triangle',
        options: {
          ...data.options,
          fill: data.options.color
        }
      }
      break;
    case 'squareFill':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          fill: data.options.color,
          angle: 0,
          cornerradius: 'no'
        }
      }
      break;
    case 'polygonFill':
      data = {
        tool: 'polygon',
        options: {
          ...data.options,
          edge: 6,
          fill: data.options.color
        }
      }
      break;
    case 'fontagonFill':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          fill: data.options.color,
          angle: 45,
          cornerradius: 'no'
        }
      }
      break;
    case 'circleFill':
      data = {
        tool: 'circle',
        options: {
          ...data.options,
          fill: data.options.color
        }
      }
      break;
    case 'rectangleFill':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          fill: data.options.color,
          angle: 0,
          cornerradius: 'yes'
        }
      }
      break;
    case 'heartOutline':
      data = {
        ...data,
        tool: 'heart'
      }
      break;
    case 'pentagonOutline':
      data = {
        tool: 'polygon',
        options: {
          ...data.options,
          edge: 5,
        }
      }
      break;
    case 'triangleOutline':
      data = {
        ...data,
        tool: 'triangle'
      }
      break;
    case 'squareOutline':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          angle: 0,
          cornerradius: 'no'
        }
      }
      break;
    case 'polygonOutline':
      data = {
        tool: 'polygon',
        options: {
          ...data.options,
          edge: 6
        }
      }
      break;
    case 'fontagonOutline':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          angle: 45,
          cornerradius: 'no'
        }
      }
      break;
    case 'circleOutline':
      data = {
        tool: 'circle',
        options: {
          ...data.options
        }
      }
      break;
    case 'rectangleOutline':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          angle: 0,
          cornerradius: 'yes'
        }
      }
      break;
    case 'eraserInactive':
      data = data
      break;
    case 'highliter':
      data = {
        ...data,
        options: {
          ...reduxToolData.options,
          strokeWidth: Number(toolOptions.strokeWidth)
        }
      }
      break;
    case 'text':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'pencil':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'select':
      data = {
        ...data,
        tool: 'pane'
      }
      break;

    default:
      data = data
      break;
  }
  console.log('tool', data);
  return data
}