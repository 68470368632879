
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateToolType } from '../../redux/actions/toolTypeAction';
import { toolTypeData } from '../../utils/toolData';
import config from '../../config';

// fullScreen toggle function
function toggleFullscreen(elem) {
  elem = elem || document.documentElement;
  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}

function openFullscreen(event) {
  var iconName = event.target.classList.value;
  if (iconName == 'icon-fullscreen') {
    event.target.classList.value = iconName + '-exit';
  } else if (iconName == 'icon-fullscreen-exit') {
    event.target.classList.value = 'icon-fullscreen';
  }
  toggleFullscreen();
}

class Header extends Component {

  updateToolTypeInRedux = (tool = '', toolOptions = {}) => {
    let data = toolTypeData(tool, toolOptions, this.props.toolTypeReduxState)
    this.props.updateToolTypeState(data)
  }

  render() {
    const { toolTypeReduxState } = this.props
    console.log('config', config.langLabels);

    return (
      <header className="bg-white" id="mainHeader">
        <div className="headerWrapper d-flex align-items-center">
          <div className="headerWrapper__left">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  // sessionOutbtn(); 
                }} >
                  <i className="icon-logout"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  // settingModalbtn(); 
                }} >
                  <i className="icon-setting"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  // showAdminChat(); 
                }} >
                  <i className="icon-chat"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="" onClick={(event) => {
                  event.preventDefault();
                  openFullscreen(event);
                }}>
                  <i className="icon-fullscreen"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="headerWrapper__middle">
            <ul className="list-inline mb-0 d-flex align-items-center">
              <li className="list-inline-item">
                <label className="headerWrapper__count font-neomd blue-color mb-0">
                  00:56:28
                    </label>
              </li>
              <li className="list-inline-item ml-3">
                <a href="" className="addBtn font-hrg" onClick={(e) => {
                  e.preventDefault();
                  // extendTimebtn();
                }} >
                  <i className="icon-add"></i>
                  <span>إضافة</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="headerWrapper__right ml-auto">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <Link
                  to=""
                  className="border_right"
                  onClick={(e) => {
                    e.preventDefault()
                    this.updateToolTypeInRedux('clear')
                  }}
                >
                  <i className="icon-blackboarderaser"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to=""
                  className="border_right"
                  onClick={(e) => {
                    e.preventDefault()
                    this.updateToolTypeInRedux('undo')
                  }}
                >
                  <i className="icon-undo"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to=""
                  className="border_right"
                  onClick={(e) => {
                    e.preventDefault()
                    this.updateToolTypeInRedux('redo')
                  }}
                >
                  <i className="icon-redo"></i>
                </Link>
              </li>
              <li className="list-inline-item inputColor">
                <label htmlFor="customColor" className="mb-0">
                  <input
                    type="color"
                    id="customColor"
                    onChange={(e) => {
                      this.updateToolTypeInRedux('color', { color: e.target.value })
                    }}
                  />
                  <Link
                    to=""
                    style={{ color: toolTypeReduxState.options.color }}
                    className="border_right"
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <i className="icon-color-shape"></i>
                  </Link>

                </label>


              </li>
              <li className="list-inline-item dropdown">
                <Link
                  to=""
                  className="dropdown-toggle"
                  id="dropdownMenuLink04"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  <i className="icon-table"></i>
                </Link>
                <div className="dropdown-menu tableDropdown" aria-labelledby="dropdownMenuLink04">
                  <form>
                    <div className="row">
                      <div className="col-6">
                        <input type="text" className="form-control" placeholder="Row" name="" />
                      </div>
                      <div className="col-6">
                        <input type="text" className="form-control" placeholder="Column" name="" />
                      </div>
                    </div>
                  </form>
                </div>
              </li>
              <li className="list-inline-item dropdown">
                <a className="dropdown-toggle" href="#" id="dropdownMenuLink01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="icon-a1"></i>
                </a>
                <div className="dropdown-menu arrowDropdown" aria-labelledby="dropdownMenuLink01">
                  <Link to=""
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('arrowDoubleHead')
                    }}
                  >
                    <i className="icon-a3"></i>
                  </Link>
                  <Link to=""
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('arrowFreeSingleHead')
                    }}
                  >
                    <i className="icon-a2"></i>
                  </Link>
                  <Link to=""
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('arrowSingleHead')
                    }}
                  >
                    <i className="icon-a1"></i>
                  </Link>
                </div>
              </li>
              <li className="list-inline-item">
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault()
                    this.updateToolTypeInRedux('line')
                  }}
                >
                  <i className="icon-linexpand"></i>
                </Link>
              </li>
              <li className="list-inline-item dropdown">
                <a className="dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="icon-dubble-shape"></i>
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('heartFill')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-heart-fill"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('pentagonFill')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-pantagon-fill"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('triangleFill')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-triangle-fill"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('squareFill')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-square-fill"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('polygonFill')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-polygon-fill"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('fontagonFill')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-fontagon-fill"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('circleFill')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-circle-fill"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('rectangleFill')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-rectangle-fill"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('heartOutline')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-heart-outline"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('pentagonOutline')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-pantagon-outline"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('triangleOutline')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-triangle-outline"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('squareOutline')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-square-outline"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('polygonOutline')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-polygon-outline"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('fontagonOutline')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-fontagon-outline"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('circleOutline')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-circle-outline"></i>
                  </Link>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      this.updateToolTypeInRedux('rectangleOutline')
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-rectangle-outline"></i>
                  </Link>
                </div>
              </li>
              <li className="list-inline-item">
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault()
                    this.updateToolTypeInRedux('eraserInactive')
                  }}
                >
                  <i className="icon-eraser_inactive"></i>
                </Link>
              </li>
              <li className="list-inline-item dropdown">
                <a
                  href="#"
                  id="dropdownMenuLink03"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="icon-highliter"></i>
                </a>
                <div className="dropdown-menu range" aria-labelledby="dropdownMenuLink03">
                  <form>
                    <div className="slidecontainer">
                      <input
                        type="range"
                        min="1"
                        max="10"
                        step="1"
                        value={toolTypeReduxState.options.strokeWidth || 1}
                        className="slider"
                        id="myRange"
                        onChange={(e) => {
                          this.updateToolTypeInRedux('highliter', { strokeWidth: e.target.value })
                        }}
                      />
                    </div>
                  </form>
                </div>
              </li>
              <li className="list-inline-item">
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault()
                    this.updateToolTypeInRedux('text')
                  }}
                >
                  <i className="icon-text"></i>
                </Link>
              </li>
              <li className="list-inline-item dropdown">
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault()
                    this.updateToolTypeInRedux('pencil')
                  }}
                // className="dropdown-toggle"
                // id="dropdownMenuLink02"
                // data-toggle="dropdown"
                // aria-haspopup="true"
                // aria-expanded="false"
                >
                  <i className="icon-pencil"></i>
                </Link>
                {/* <div className="dropdown-menu range" aria-labelledby="dropdownMenuLink02">
                  <form>
                    <div className="slidecontainer">
                      <input type="range" min="1" max="100" defaultValue="50" className="slider" id="myRange" />
                    </div>
                  </form>
                </div> */}
              </li>
              <li className="list-inline-item">
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault()
                    this.updateToolTypeInRedux('select')
                  }}
                >
                  <i className="icon-near-me"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    toolTypeReduxState: state.toolTypeProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateToolTypeState: data => dispatch(updateToolType(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)