// import all settings for production env
import productionConfig from "./production";

// import all settings for development env
import developmentConfig from "./development";

let env = 'development';
// let env = 'production';

let config = env === 'development' ? developmentConfig : productionConfig;

export default config;