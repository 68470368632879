import React, { Component } from 'react';
import { connect } from 'react-redux';
import Canvas from "../../components/canvas";

class Whiteboard extends Component {

  render() {
    const { whiteBoardReduxState: { whiteBoards, activeWhiteBoardId } } = this.props

    return (
      <div className="white-board" id="whiteBoard">
        {
          whiteBoards.length > 0 && whiteBoards.map((board, index) => {
            return <div key={board} className={`${activeWhiteBoardId === board ? 'd-block' : 'd-none'}`}>
              <Canvas activeWhiteBoardId={board} />
            </div>
          })
        }
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    whiteBoardReduxState: state.whiteBoardProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Whiteboard)