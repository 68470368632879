import { ADD_WHITE_BOARD, REMOVE_WHITE_BOARD, NEW_ACTIVE_WHITE_BOARD, UPDATE_CANVAS_WHITE_BOARD, UPDATE_CANVAS_RENDER_WHITE_BOARD } from "../reduxConst/whiteBoardConst";

export const addWhiteBoard = () => {
  return {
    type: ADD_WHITE_BOARD
  }
}

export const removeWhiteBoard = (data) => {
  return {
    type: REMOVE_WHITE_BOARD,
    data
  }
}

export const newActiveWhiteBoard = (data) => {
  return {
    type: NEW_ACTIVE_WHITE_BOARD,
    data
  }
}