import React, { Component } from 'react'

export default class SideBar extends Component {

  render() {
    return (
      <section className="chatSidebar">
        <ul className="nav nav-tabs chatSidebar__tabs border-0" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="attendance-tab" data-toggle="tab" href="#attendance" role="tab" aria-controls="attendance" aria-selected="true"> <span className="count">100/<strong className="mr-1 font-neomd">80</strong></span>  <span className="text">Attendance</span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="chat-tab" data-toggle="tab" href="#chat" role="tab" aria-controls="chat" aria-selected="false"><span className="text">Chat</span></a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade  show active" id="attendance" role="tabpanel" aria-labelledby="attendance-tab">
            <ul className="attendanceList attendanceList--forAll list-unstyled p-0">
              <li>
                <ul className="attendanceList__left list-inline">
                  <li className="list-inline-item">
                    <a href="">
                      <i className="icon-mouse-countrol"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="" >
                      <i className="icon-videocam"></i>
                    </a>
                  </li>
                  <li className="list-inline-item dropdown">
                    <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="icon-voice"></i>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="" >minutes 10</a>
                      <a className="dropdown-item" href="" >minutes 5</a>
                      <a className="dropdown-item" href="" >Open</a>
                    </div>
                  </li>
                </ul>
                <div className="attendanceList__right">
                  <p className="mb-0">For all Attendance</p>
                </div>
              </li>
            </ul>

            <div className="searchForm" dir="rtl">
              <form className="position-relative">
                <div className="form-group mb-0">
                  <input type="text" className="form-control rounded-0" placeholder="بحث عن طالب" />
                  <button className="btn btn-light p-0 border-0"><i className="icon-search"></i></button>
                  <a href=""  className="valueRefresh">
                    <i className="icon-cancel"></i>
                  </a>
                </div>
              </form>
            </div>


            <div className="attendancelisting">
              <h3 className="text-right">Avaliable (80)</h3>
              <ul className="attendanceList list-unstyled">
                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>

                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Connor Wise</p>
                  </div>
                </li>

                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>

                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Connor Wise</p>
                  </div>
                </li>

                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>
                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Alberta Daniels</p>
                  </div>
                </li>

                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>

                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Connor Wise</p>
                  </div>
                </li>

                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>
                <li>
                  <ul className="attendanceList__left list-inline">
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-mouse-countrol"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" >
                        <i className="icon-videocam"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="icon-voice"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="" >minutes 10</a>
                        <a className="dropdown-item" href="" >minutes 5</a>
                        <a className="dropdown-item" href="" >Open</a>
                      </div>
                    </li>
                  </ul>
                  <div className="attendanceList__right">
                    <p className="mb-0">Alberta Daniels</p>
                  </div>
                </li>


              </ul>

              <h3 className="text-right mt-4">Absent (20)</h3>
              <ul className="attendanceList list-unstyled">
                <li className="justify-content-end">
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>

                <li className="justify-content-end">
                  <div className="attendanceList__right">
                    <p className="mb-0">Connor Wise</p>
                  </div>
                </li>

                <li className="justify-content-end">
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>

                <li className="justify-content-end">
                  <div className="attendanceList__right">
                    <p className="mb-0">Connor Wise</p>
                  </div>
                </li>

                <li className="justify-content-end">
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>

                <li className="justify-content-end">
                  <div className="attendanceList__right">
                    <p className="mb-0">Connor Wise</p>
                  </div>
                </li>

                <li className="justify-content-end">
                  <div className="attendanceList__right">
                    <p className="mb-0">Imad Qasimi</p>
                  </div>
                </li>

                <li className="justify-content-end">
                  <div className="attendanceList__right">
                    <p className="mb-0">Connor Wise</p>
                  </div>
                </li>

              </ul>
            </div>
          </div>
          <div className="tab-pane fade position-relative" id="chat" role="tabpanel" aria-labelledby="chat-tab">
            <div className="chatBox h-100">
              <ul className="chatBox__options list-inline d-flex justify-content-between mb-0">
                <li className="list-inline-item">
                  <a href="" className="active requestTab"><span className="badge badge-light">5</span> Requests</a>
                </li>
                <li className="list-inline-item">
                  <a href="" className="questionsTab"><span className="badge badge-light">10</span> Questions</a>
                </li>
                <li className="list-inline-item">
                  <a href="" className="allTab">all</a>
                </li>
              </ul>

              <div className="listingWrapper">
                <div >
                  <ul className="list-unstyled chatBox__listing text-right mb-0" id="requestsChat">
                    <li>
                      <div className="chatBox__listing__msgBoxInfo">
                        <div className="top">
                          <p className="mb-0">request from Aziz Amri1min <span className="ml-2 icon-voice align-middle"></span></p>
                        </div>
                        <ul className="list-unstyled bottom border-top-0 d-flex justify-content-between bg-white align-items-center" id="RequestTime">
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">Open</a></li>
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">5Min</a></li>
                          <li className="text-center flex-fill"><a href=""  className="selectTime d-block">1Min</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="chatBox__listing__msgBoxInfo">
                        <div className="top topSend">
                          <p className="mb-0">request from Aziz Amri1min <span className="ml-2 icon-voice align-middle"></span></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="chatBox__listing__msgBoxInfo">
                        <div className="top">
                          <p className="mb-0">request from Aziz Amri1min <span className="ml-2 icon-voice align-middle"></span></p>
                        </div>
                        <ul className="list-unstyled bottom border-top-0 d-flex justify-content-between bg-white align-items-center">
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">Open</a></li>
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">5Min</a></li>
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">1Min</a></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <ul className="list-unstyled chatBox__listing text-right mb-0" id="Questions" style={{ display: "none" }}>
                    <li>
                      <div id="div-9" className="chatBox__listing__msgBox">
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="9" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center"><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>ahmed sawafi</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div id="div-10" className="chatBox__listing__msgBox ">
                        <ul className="chatBox__listing__msgBox__share list-unstyled" id="MCK9">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="10" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>Imad Qasimi</span>
                          <p className="mb-0">
                            clear
											</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div id="div-11" className="chatBox__listing__msgBox">
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="11" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>Ali Salmis</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                        </div>
                      </div>
                    </li>
                    <li className="chatReplay" id="chatReplay" style={{ display: "none" }}>
                      <div className="chatBox__listing__msgBox chatBox__listing__msgBox--reply d-flex border-right-0">
                        <div className="CloseBtn">
                          <a href="" className="d-flex justify-content-center align-items-center"><span className="icon-cancel"></span></a>
                        </div>
                        <div>
                          <span>ahmed sawafi</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                        </div>
                      </div>
                      <div className="chatBox__listing__reply">
                      </div>
                    </li>
                  </ul>
                  <ul className="list-unstyled chatBox__listing text-right mb-0 " id="AllChat" style={{ display: "none" }}>
                    <li>
                      <div id="div-1" className="chatBox__listing__msgBox">
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="1" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>ahmed sawafi</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div id="div-2" className="chatBox__listing__msgBox chatBox__listing__msgBox--Creply">
                        <ul className="chatBox__listing__msgBox__share list-unstyled" style={{ opacity: 1 }}>
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="2" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                        <div>
                          <span>ahmed sawafi</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                          <div className="reply ml-0">
                            <p className="mb-0">
                              how can i
												</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div id="div-3" className="chatBox__listing__msgBox ">
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="3" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>Imad Qasimi</span>
                          <p className="mb-0">
                            clear
											</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div id="div-4" className="chatBox__listing__msgBox">
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="4" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>Ali Salmis</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="chatBox__listing__msgBoxInfo">
                        <div className="top">
                          <p className="mb-0">request from Aziz Amri1min <span className="ml-2 icon-voice align-middle"></span></p>
                        </div>
                        <ul className="list-unstyled bottom border-top-0 d-flex justify-content-between bg-white align-items-center" id="RequestTime">
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">Open</a></li>
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">5Min</a></li>
                          <li className="text-center flex-fill"><a href=""  className="selectTime d-block">1Min</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div id="div-5" className="chatBox__listing__msgBox ">
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="5" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>Imad Qasimi</span>
                          <p className="mb-0">
                            clear
											</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="chatBox__listing__msgBox chatBox__listing__msgBox--favorite chatBox__listing__msgBox--Creply">
                        <ul className="chatBox__listing__msgBox__share list-unstyled" style={{ opacity: 1 }}>
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                        <div>
                          <span>ahmed sawafi</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                          <div className="reply ml-0">
                            <p className="mb-0">
                              how can i
												</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div id="div-6" className="chatBox__listing__msgBox">
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="6" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>Ali Salmis</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="chatBox__listing__msgBoxInfo">
                        <div className="top">
                          <p className="mb-0">request from Aziz Amri1min <span className="ml-2 icon-voice align-middle"></span></p>
                        </div>
                        <ul className="list-unstyled bottom border-top-0 d-flex justify-content-between bg-white align-items-center" id="RequestTime">
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">Open</a></li>
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">5Min</a></li>
                          <li className="text-center flex-fill"><a href=""  className="selectTime d-block">1Min</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div id="div-7" className="chatBox__listing__msgBox " >
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="7" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>Imad Qasimi</span>
                          <p className="mb-0">
                            clear
											</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div id="div-8" className="chatBox__listing__msgBox" >
                        <ul className="chatBox__listing__msgBox__share list-unstyled">
                          <li>
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" id="8" className="checked" />
                                <span className="checkbox-icon-wrapper">
                                  <span className="checkbox-icon icon-star-outline"></span>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <a href="" className="d-flex justify-content-center align-items-center" ><span className="icon-reply"></span></a>
                          </li>
                        </ul>
                        <div>
                          <span>Ali Salmis</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="chatBox__listing__msgBoxInfo">
                        <div className="top">
                          <p className="mb-0">request from Aziz Amri1min <span className="ml-2 icon-voice align-middle"></span></p>
                        </div>
                        <ul className="list-unstyled bottom border-top-0 d-flex justify-content-between bg-white align-items-center" id="RequestTime">
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">Open</a></li>
                          <li className="text-center flex-fill"><a href="" className="selectTime d-block">5Min</a></li>
                          <li className="text-center flex-fill"><a href=""  className="selectTime d-block">1Min</a></li>
                        </ul>
                      </div>
                    </li>
                    <li className="chatReplay" id="chatReplay" style={{ display: "none" }}>
                      <div className="chatBox__listing__msgBox chatBox__listing__msgBox--reply d-flex border-right-0">
                        <div className="CloseBtn">
                          <a href="" className="d-flex justify-content-center align-items-center"><span className="icon-cancel"></span></a>
                        </div>
                        <div>
                          <span>ahmed sawafi</span>
                          <p className="mb-0">
                            how can i draw a line ? i mean the easy way
											</p>
                        </div>
                      </div>
                      <div className="chatBox__listing__reply">
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="chatBox__footer bg-white">
                <div className="d-lg-flex align-items-center">
                  <ul className="list-inline chatBox__footer__option mb-0">
                    <li className="list-inline-item">
                      <a href=""><span className="icon-send"></span></a>
                    </li>
                    <li className="list-inline-item">
                      <a href=""><span className="icon-smile"></span></a>
                    </li>
                  </ul>
                  <div className="form-group mb-0 w-100 text-right">
                    <textarea rows="2" className="form-control text-right h-auto" placeholder="Reply"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
