
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { fabric } from 'fabric';
import bootbox from 'bootbox';

class Canvas extends React.Component {

  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }

  static defaultProps = {
    width: 600,
    height: 400
  }

  constructor(props) {
    super(props)

    this.state = {
      canvas: null,
      isDown: false,
      drawingTool: props.toolTypeReduxState.tool,
      origX: '',
      originY: '',
      currentObject: '',
      color: 'black',
      strokeWidth: 1,
      fill: '',
      angle: 0,
      edge: 6,
      cornerradius: 'no'
    }
  }

  componentDidMount() {
    const canvas = new fabric.Canvas(`canvas_${this.props.activeWhiteBoardId}`, {
      selection: false,
      preserveObjectStacking: true,
      selectionColor: 'transparent',
      selectionLineWidth: 1,
      controlsAboveOverlay: true,
      centeredScaling: true,
      allowTouchScrolling: true
    });
    canvas.isDrawingMode = false;

    let whiteBoardWidth = document.getElementById("whiteBoard").offsetWidth
    let whiteBoardHeight = document.getElementById("whiteBoard").offsetHeight

    canvas.setHeight(whiteBoardHeight);
    canvas.setWidth(whiteBoardWidth);
    canvas.renderAll();

    this.setState({
      canvas
    }, () => {
      this.renderShapes();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.toolTypeReduxState !== this.props.toolTypeReduxState) {
      const {
        tool,
        options: {
          color,
          strokeWidth,
          fill,
          angle,
          edge,
          cornerradius,
        }
      } = this.props.toolTypeReduxState

      this.setState({
        drawingTool: tool,
        color: color,
        strokeWidth: strokeWidth,
        fill: fill,
        angle: angle,
        edge: edge,
        cornerradius: cornerradius,
      }, () => {
        this.renderShapes();
      });
    }
  }


  regularPolygonPoints = (sideCount, radius) => {
    var sweep = Math.PI * 2 / sideCount;
    var cx = radius;
    var cy = radius;
    var points = [];
    for (var i = 0; i < sideCount; i++) {
      var x = cx + radius * Math.cos(i * sweep);
      var y = cy + radius * Math.sin(i * sweep);
      points.push({ x: x, y: y });
    }
    return (points);
  }

  renderShapes = () => {
    let currentObject = '';
    let origX = '';
    let origY = '';
    let canvas = this.state.canvas;

    if (this.state.drawingTool == 'clear') {
      this.clearCanvas(canvas)
    }

    canvas.isDrawingMode = false;
    canvas.selection = false;

    canvas.on('before:selection:cleared', function () {

    });

    canvas.on('mouse:down', (o) => {
      this.setState({
        isDown: true
      }, () => {
        let { drawingTool } = this.state;

        let pointer = canvas.getPointer(o.e);
        origX = pointer.x;
        origY = pointer.y;
        currentObject = this.renderShapesByType(canvas, drawingTool, o.e, origX, origY);
        if (currentObject) {
          this.setState({
            currentObject: currentObject
          })
        }
      });
    });

    canvas.on('mouse:move', (o) => {
      if (!this.state.isDown)
        return;
      if (this.state.currentObject) {
        if (this.state.currentObject.strokeWidth != this.state.strokeWidth) {
          this.state.currentObject.set({ strokeWidth: this.state.strokeWidth });
        }
        let { drawingTool } = this.state;
        this.renderShapesByType(canvas, drawingTool, o.e, origX, origY);
      }
    });

    canvas.on('mouse:up', (o) => {
      this.setState({
        isDown: false,
        currentObject: ''
      }, () => {
        canvas.off('mouse:down');
        canvas.off('mouse:move');
        canvas.off('mouse:up');
        canvas.off('object:moving');
        canvas.selection = true;
      });
    });

    if (this.state.drawingTool == "text") {
      canvas.on('text:changed', (e) => {

      });
    }

    canvas.on('object:selected', (o) => {
      if (this.state.drawingTool == "eraser") {
        if (canvas.getActiveObject()) {
          canvas.isDrawingMode = false;
          canvas.remove(canvas.getActiveObject());
        }
      }
    });
  }

  renderShapesByType = (canvas, drawingTool, event, origX, origY) => {
    switch (drawingTool) {
      case 'line':
        return this.drawLine(canvas, event);
      case 'circle':
        return this.drawCircle(canvas, event, origX, origY);
      case 'triangle':
        return this.drawTriangle(canvas, event, origX, origY);
      case 'rectangle':
        return this.drawRectangle(canvas, event, origX, origY);
      case 'polygon':
        return this.drawPolygon(canvas, event, origX, origY);
      case 'pencil':
        return this.freeDrawing(canvas, event);
      case 'text':
        return this.writeText(canvas, event, origX, origY);
      case 'heart':
        return this.drawHeart(canvas, event, origX, origY);
      case 'pane':
        return this.enableSelection(canvas);
      default:
        return '';
    }
  }


  enableSelection = (canvas) => {
    canvas.selection = true;
  }

  clearCanvas = (canvas) => {
    bootbox.confirm({
      message: "Are you sure you want to clear whiteboard?",
      buttons: {
        confirm: {
          label: 'Yes',
          className: 'btn-success'
        },
        cancel: {
          label: 'No',
          className: 'btn-danger'
        }
      },
      callback: (result) => {
        if (result) {
          canvas.clear();
        }
      }
    });
  }

  drawHeart = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      let width = Math.abs(origX - pointer.x) / 2;
      let scale = width / 100;
      this.state.currentObject.set({ scaleX: scale, scaleY: scale });
    } else {
      currentObject = new fabric.Path('M 272.70141,238.71731 \
      C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731  \
      C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535 \
      C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731 \
      C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731  \
      C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481 \
      C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731  \
      z ', {
        left: origX,
        top: origY,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: this.state.strokeWidth
      });
      currentObject.set({ scaleX: 0, scaleY: 0 });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  drawCircle = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      // code for update selected circle
      var radius = Math.max(Math.abs(origY - pointer.y), Math.abs(origX - pointer.x)) / 2;
      if (radius > this.state.currentObject.strokeWidth) {
        radius -= this.state.currentObject.strokeWidth / 2;
      }
      this.state.currentObject.set({ radius: radius });
      if (origX > pointer.x) {
        this.state.currentObject.set({ originX: 'right' });
      } else {
        this.state.currentObject.set({ originX: 'left' });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({ originY: 'bottom' });
      } else {
        this.state.currentObject.set({ originY: 'top' });
      }
      // code for update selected circle end
    } else {
      origX = pointer.x;
      origY = pointer.y;
      currentObject = new fabric.Circle({
        left: origX,
        top: origY,
        originX: 'left',
        originY: 'top',
        radius: pointer.x - origX,
        angle: 0,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: 0
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  drawTriangle = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      // code for update selected traingle
      if (origX > pointer.x) {
        this.state.currentObject.set({
          left: Math.abs(pointer.x)
        });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({
          top: Math.abs(pointer.y)
        });
      }
      this.state.currentObject.set({
        width: Math.abs(origX - pointer.x)
      });
      this.state.currentObject.set({
        height: Math.abs(origY - pointer.y)
      });
      // code for update selected traingle
    } else {
      currentObject = new fabric.Triangle({
        left: origX,
        top: origY,
        width: pointer.x - origX,
        height: pointer.y - origY,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: 0,
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  drawLine = (canvas, event) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      this.state.currentObject.set({ x2: pointer.x, y2: pointer.y });
    } else {
      let points = [pointer.x, pointer.y, pointer.x, pointer.y];
      currentObject = new fabric.Line(points, {
        strokeWidth: 0,
        stroke: this.state.color,
        originX: 'center',
        originY: 'center'
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  drawPolygon = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      if (origX > pointer.x) {
        this.state.currentObject.set({
          left: Math.abs(pointer.x)
        });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({
          top: Math.abs(pointer.y)
        });
      }
      let radius = Math.max(Math.abs(origY - pointer.y), Math.abs(origX - pointer.x)) / 2;
      let points = this.regularPolygonPoints(this.state.edge, radius);
      this.state.currentObject.set({ points: points });
    } else {
      let points = this.regularPolygonPoints(this.state.edge, 0);
      currentObject = new fabric.Polygon(points, {
        stroke: this.state.color,
        left: origX,
        top: origY,
        fill: this.state.fill,
        strokeWidth: 0
      }, false);
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  drawRectangle = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      // code for update selected Rectangle
      if (origX > pointer.x) {
        this.state.currentObject.set({
          left: Math.abs(pointer.x)
        });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({
          top: Math.abs(pointer.y)
        });
      }
      this.state.currentObject.set({
        width: Math.abs(origX - pointer.x)
      });
      this.state.currentObject.set({
        height: Math.abs(origY - pointer.y)
      });

      if (this.state.cornerradius == 'yes') {
        let radius = (Math.abs(origX - pointer.x) / 2) * 0.20;
        this.state.currentObject.set({ rx: radius, ry: radius });
      }
      // code for update selected Rectangle
    } else {
      currentObject = new fabric.Rect({
        left: origX,
        top: origY,
        width: pointer.x - origX,
        height: pointer.y - origY,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: 0,
        angle: this.state.angle,
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  writeText = (canvas, event, origX, origY) => {
    let currentObject = ''
    if (this.state.currentObject) {
      // code for update selected text
      this.state.currentObject.setCoords();
      // code for update selected text
    } else {
      currentObject = new fabric.IText('', {
        width: 150,
        left: origX,
        top: origY,
        padding: 10,
        fontFamily: 'Times New Roman',
        fill: this.state.color,
        fontSize: 18,
        cornerSize: 5,
        lockScalingY: false,
        lockUniScaling: false,
        perPixelTargetFind: true,
      });
      canvas.add(currentObject);
      currentObject.enterEditing();
      currentObject.hiddenTextarea.focus();
    }
    canvas.renderAll();
    return currentObject;
  }

  freeDrawing = (canvas, event) => {
    canvas.isDrawingMode = true;
    canvas.selection = false;
    canvas.freeDrawingBrush.color = this.state.color;
    canvas.freeDrawingBrush.width = this.state.strokeWidth;
    return '';
  }

  render() {
    const { width, height, activeWhiteBoardId } = this.props
    // if (['', null, false, undefined].includes(document.getElementById('whiteBoard'))) {
    //   return <></>
    // }
    // let whiteBoardWidth = document.getElementById("whiteBoard").offsetWidth
    // let whiteBoardHeight = document.getElementById("whiteBoard").offsetHeight
    return (
      <>
        {/* <canvas id={`canvas_${activeWhiteBoardId}`} className="canvas-whiteboard" width={whiteBoardWidth} height={whiteBoardHeight} /> */}
        <canvas id={`canvas_${activeWhiteBoardId}`} />
        {/* <button onClick={e => {
          e.preventDefault()
          console.log(this.state.canvas.toJSON())
        }}>To JSON</button> */}
      </>
    )
  }

}

let mapStateToProps = (state) => {
  return {
    whiteBoardReduxState: state.whiteBoardProps,
    toolTypeReduxState: state.toolTypeProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Canvas)