import React from 'react';
import { Switch, Router } from 'react-router-dom';
import RouteWithLayout from './routeWithLayout';
import TeacherLayout from '../layouts/teacher';
import NotFoundLayout from '../layouts/notFound';
import PageNotFound from '../notFound';
import browserHistory from '../../utils/history';
import whiteBoard from '../../containers/whiteBoard';


export default class Routes extends React.Component {
  render() {
    return (
      <Router history={browserHistory}>
        <Switch>
          <RouteWithLayout
            component={whiteBoard}
            exact
            layout={TeacherLayout}
            path="/"
          />
          <RouteWithLayout
            component={PageNotFound}
            exact
            layout={NotFoundLayout}
            path="*"
          />
        </Switch>
      </Router>
    );
  }
}
