import { ADD_WHITE_BOARD, REMOVE_WHITE_BOARD, NEW_ACTIVE_WHITE_BOARD } from "../reduxConst/whiteBoardConst";
import config from '../../config';

let nowTimestamp = () => {
  return new Date().getTime()
}

let addWhiteBoardRedux = (state, action) => {
  let newBoardId = nowTimestamp();
  let addWhiteBoard = state;
  if (addWhiteBoard.whiteBoards.length < config.maxBoards) {
    addWhiteBoard.activeWhiteBoardId = newBoardId
    addWhiteBoard.whiteBoards.push(newBoardId)
  }

  return {
    ...state,
    ...addWhiteBoard
  }
}

let removeWhiteBoardRedux = (state, action) => {
  let removeWhiteBoard = state;
  if (removeWhiteBoard.whiteBoards.length > 1) {
    let removeBoardId = action.data;
    let removeIndex = removeWhiteBoard.whiteBoards.findIndex(data => data === removeBoardId);
    let newActiveWhiteBoardId = ''
    if (removeIndex === 0) {
      newActiveWhiteBoardId = removeWhiteBoard.whiteBoards[1]
    } else {
      newActiveWhiteBoardId = removeWhiteBoard.whiteBoards[removeIndex - 1]
    }
    removeWhiteBoard.activeWhiteBoardId = newActiveWhiteBoardId
    removeWhiteBoard.whiteBoards.splice(removeIndex, 1);
  }

  return {
    ...state,
    ...removeWhiteBoard
  }
}

let newActiveWhiteBoard = (state, action) => {
  return {
    ...state,
    activeWhiteBoardId: action.data
  }
}

let whiteBoardState = {
  whiteBoards: [
    nowTimestamp()
  ]
}

whiteBoardState.activeWhiteBoardId = whiteBoardState.whiteBoards[0]

let whiteBoardCollectionReducer = (state = whiteBoardState, action) => {
  switch (action.type) {
    case ADD_WHITE_BOARD:

      return addWhiteBoardRedux(state, action);

    case REMOVE_WHITE_BOARD:

      return removeWhiteBoardRedux(state, action);

    case NEW_ACTIVE_WHITE_BOARD:

      return newActiveWhiteBoard(state, action);

    default:
      return state;
  }

}

export default whiteBoardCollectionReducer;