import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../header';
import SideBar from '../../sidebar';
import Footer from '../../footer';

const TeacherLayout = (props) => {
  const { children } = props;

  return (
    <>
      <Header />
      <main className="main-content homePage">
        <div className="mainWrapper">
          <div className="mainWrapper__left">
            <SideBar />
          </div>
          <div className="mainWrapper__right bg-white">
            {children}
            <Footer />
          </div>
        </div>
      </main>
    </>
  );
};

TeacherLayout.propTypes = {
  children: PropTypes.node
};

export default TeacherLayout;
